import { Box, Text, Link, Heading, Image, Center } from '@chakra-ui/react'

function publicUrl(path) {
  return `${process.env.PUBLIC_URL}${path}`
}

function App() {
  return (
    <Box
      bgColor='#fefdf9'
      color='black'
      textAlign='center'
      margin='0 auto'
    >
      <Center>
        <Image
          src={publicUrl('images/logo-transparent.png')}
          width='270px'
          alt="Nora's Kitchen"
        />
      </Center>
        <Box>
      <Heading marginY='3' size='md'>
        Ordering
      </Heading>

      <Text>
        WhatsApp:{' '}
        <Link
          color='blue'
          href='https://wa.me/18296395377'
          isExternal
        >
         +1 829 639 5377
        </Link>
      </Text>
      <Text>
        Facebook:{' '}
        <Link color='blue' href='https://facebook.com/noraskitchenlt' isExternal>
          fb.com/noraskitchenlt
        </Link>
      </Text>
      </Box>
      <Center minW="800px">
        <Link href={publicUrl('images/menu-oct-25-2022.png')} isExternal download>
          <Image
            src={publicUrl('images/menu-oct-25-2022.png')}
            marginY='6'
            maxW='800px'
            alt='Menu'
          />
        </Link>
      </Center>
    </Box>
  )
}

export default App
